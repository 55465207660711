.App {
  background-color: #ffe135;
  text-align: left;
}

.App-header {
  background-color: #161c20;
  /* min-height: 100vh; */
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: #f3f6fb;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
